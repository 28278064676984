/**
 * WordPress dependencies
 */
const { PanelBody, Placeholder, SelectControl, ServerSideRender, TextControl, TextareaControl, ToggleControl } = wp.components;
const { InspectorControls } = wp.editor;
const { Component, Fragment } = wp.element;
const { __ } = wp.i18n;

/**
 * Internal dependencies
 */
import './block.scss';
import icon from './icon'

class Edit extends Component {

	constructor() {

		super( ...arguments );

		// Set initial state.
		this.state = { formWasDeleted: false };

		// Bind events.
		this.setFormId = this.setFormId.bind( this );

		// Get defined form ID.
		const { formId } = this.props.attributes;

		// If form has been selected, disable preview / reset.
		if ( formId ) {

			// Get form object.
			const form = Edit.getForm( formId );

			// If form was not found, reset block.
			if ( ! form ) {

				// Reset form ID.
				this.props.setAttributes( { formId: '' } );

				// Set failed state.
				this.state = { formWasDeleted: true };

			// If form was found and has conditional logic, disable preview.
			} else if ( form && form.hasConditionalLogic ) {
				this.props.setAttributes( { formPreview: false } );
			}

		}

	}

	componentWillUnmount() {

		this.unmounting = true;

	}

	setFormId( formId ) {

		let form = Edit.getForm( formId );

		this.props.setAttributes( { formId } );
		this.setState( { formWasDeleted: false } );

		if ( form && form.hasConditionalLogic ) {
			this.props.setAttributes( { formPreview: false } );
		}

	}

	static getForm( formId ) {

		return gform_block_form.forms.find( ( form ) => form.id == formId );

	}

	static getFormOptions() {

		let options = [
			{
				label: __( 'Select a Form', 'gravityforms' ),
				value: '',
			}
		];

		for ( let i = 0; i < gform_block_form.forms.length; i++ ) {

			let form = gform_block_form.forms[ i ];

			options.push( {
				label: form.title,
				value: form.id,
			} );

		}

		return options;

	}

	render() {

		let { formId, title, description, ajax, tabindex, formPreview, fieldValues } = this.props.attributes;

		const { setAttributes, isSelected } = this.props;

		const toggleTitle = () => setAttributes( { title: ! title } );
		const toggleDescription = () => setAttributes( { description: ! description } );
		const toggleAjax = () => setAttributes( { ajax: ! ajax } );
		const toggleFormPreview = () => setAttributes( { formPreview: ! formPreview } );

		const updateTabindex = ( tabindex ) => setAttributes( { tabindex } );

		const setFormIdFromPlaceholder = ( e ) => this.setFormId( e.target.value );

		const controls = [
			isSelected && (
				<InspectorControls key="inspector">
					<PanelBody
						title={ __( 'Form Settings', 'gravityforms' ) }
					>
						<SelectControl
							label={ __( 'Form', 'gravityforms' ) }
							value={ formId }
							options={ Edit.getFormOptions() }
							onChange={ this.setFormId }
						/>
						{
							formId && <ToggleControl
								label={ __( 'Form Title', 'gravityforms' ) }
								checked={ title }
								onChange={ toggleTitle }
							/>
						}
						{
							formId && <ToggleControl
								label={ __( 'Form Description', 'gravityforms' ) }
								checked={ description }
								onChange={ toggleDescription }
							/>
						}
					</PanelBody>
					{
						formId && <PanelBody
							title={ __( 'Advanced', 'gravityforms' ) }
							initialOpen={ false }
							className="gform-block__panel"
						>
							{
								formId && ! Edit.getForm( formId ).hasConditionalLogic &&
								<ToggleControl
									label={ __( 'Preview', 'gravityforms' ) }
									checked={ formPreview }
									onChange={ toggleFormPreview }
								/>
							}
							<ToggleControl
								label={ __( 'AJAX', 'gravityforms' ) }
								checked={ ajax }
								onChange={ toggleAjax }
							/>
							<TextareaControl
								label={ __( 'Field Values', 'gravityforms' ) }
								value={ fieldValues }
								onChange={ ( fieldValues ) => { setAttributes( { fieldValues } ) } }
							/>
							<TextControl
								className="gform-block__tabindex"
								label={ __( 'Tabindex', 'gravityforms' ) }
								type="number"
								value={ tabindex }
								onChange={ updateTabindex }
								placeholder="-1"
							/>
							<Fragment>
								Form ID: { formId }
							</Fragment>
						</PanelBody>
					}
				</InspectorControls>
			),
		];

		if ( ! formId || ! formPreview ) {

			const { formWasDeleted } = this.state;

			return [
				controls,
				(
					formWasDeleted && <div className="gform-block__alert gform-block__alert-error">
						<p>{ __( 'The selected form has been deleted or trashed. Please select a new form.', 'gravityforms' ) }</p>
					</div>
				),
				<Placeholder key="placeholder" className="wp-block-embed gform-block__placeholder">
					<div className="gform-block__placeholder-brand">
						<div className={ 'gform-icon' }>{ icon }</div>
						<p><strong>Gravity Forms</strong></p>
					</div>
					<form>
						<select value={ formId } onChange={ setFormIdFromPlaceholder }>
							{ Edit.getFormOptions().map( form =>
								<option key={ form.value } value={ form.value }>{ form.label }</option>,
							) }
						</select>
					</form>
				</Placeholder>,
			];

		}

		return [
			controls,
			<ServerSideRender
				key="form_preview"
				block="gravityforms/form"
				attributes={ this.props.attributes }
			/>,
		];

	}

}

export default Edit