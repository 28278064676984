/**
 * WordPress dependencies
 */
const { __ } = wp.i18n;
const { registerBlockType } = wp.blocks;

/**
 * Internal dependencies
 */
import Edit from './edit'
import icon from './icon'

registerBlockType( 'gravityforms/form', {

	title:       __( 'Form', 'gravityforms' ),
	description: __( 'Select a form below to add it to your page.', 'gravityforms' ),
	category:    'embed',
	supports:    {
		customClassName: false,
		className:       false,
		html:            false,
	},
	keywords:    [ 'gravity forms', 'newsletter', 'contact' ],
	attributes:  {
		formId:      {
			type: 'string',
		},
		title:       {
			type:    'bool',
			default: true,
		},
		description: {
			type:    'bool',
			default: true,
		},
		ajax:        {
			type:    'bool',
			default: false,
		},
		tabindex:    {
			type: 'string',
		},
		fieldValues: {
			type: 'string',
		},
		formPreview: {
			type:    'bool',
			default: true,
		},
	},
	icon:        icon,

	transforms: {
		from: [
			{
				type:       'shortcode',
				tag:        [ 'gravityform', 'gravityforms' ],
				attributes: {
					formId:      {
						type:      'string',
						shortcode: ( { named: { id } } ) => {
							return parseInt( id ).toString();
						},
					},
					title:       {
						type:      'bool',
						shortcode: ( { named: { title } } ) => {
							return 'true' === title;
						},
					},
					description: {
						type:      'bool',
						shortcode: ( { named: { description } } ) => {
							return 'true' === description;
						},
					},
					ajax:        {
						type:      'bool',
						shortcode: ( { named: { ajax } } ) => {
							return 'true' === ajax;
						},
					},
					tabindex:    {
						type:      'string',
						shortcode: ( { named: { tabindex } } ) => {
							return isNaN( tabindex ) ? null : parseInt( tabindex ).toString();
						},
					}
				},
			}
		]
	},

	edit: Edit,

	save() {
		return (
			null
		);
	},

} );
